<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>输入本次想要申请的账号数量：</span>
            <el-input
              v-model.number="applyOption.count"
              placeholder="请输入"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="openAction">开通</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <el-table :data="tableData" style="width: 100%" border height="750">
        <el-table-column prop="ApplyTime" label="申请时间"> </el-table-column>
        <el-table-column prop="Email" label="返回邮箱"> </el-table-column>
        <el-table-column label="是否开通">
          <template #default="scope">
            <p>{{scope.row.IsOpen==1?'是':'否'}}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {GetApplyEmail,AddApplyEmail} from '@/helper/robot'

export default {
  setup() {
    const applyOption = reactive({
      count: "",
    });
    const tableData = ref([]);

    onMounted(() => {
      searchAction();
    });

    /**************************搜索************************/
    function searchAction(params) {
      let param = {
        PageNum:1,
        PageSize:10000
      }
      GetApplyEmail(param).then(res=>{
        tableData.value = res.List
      })
    }

    /**************************点击事件************************/
    function openAction() {
      let num = parseInt(applyOption.count)
      if(!num||num<=0){
        ElMessage({
          type:'info',
          message: '需填写开通数量',
        })
        return
      }
      ElMessageBox.confirm(
          "开通需要一段时间才能将邮箱生成完成，且开通后将无法撤销，您确定要这么做吗？",
          "开通提醒",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }).then(() => {
            AddApplyEmail({Count:applyOption.count}).then(res=>{
              ElMessage({
                type:'success',
                message: '开通成功',
              })
              searchAction()
            })
          }).catch(() => {});
    }
    return {
      applyOption,
      tableData,
      openAction,
      searchAction
    };
  },
};
</script>

<style lang="scss" scoped>
.table-head {
  border: none;
}
.table-box {
  border: none;
}
</style>