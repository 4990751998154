import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//获取省市
export function GetProvinceList() {
    return $axios.post(`${baseURL}/Robot/GetProvinceList`);
}
//获取城市
export function GetCityList(data) {
  return $axios.post(`${baseURL}/Robot/GetCityList`,data);

}

//获取助手列表
export function GetRobotList(data) {
  return $axios.post(`${baseURL}/Robot/List`,data);
  
}

//获取在指定群内的其他助手
export function GetExtRobotList(data) {
  return $axios.post(`${baseURL}/Group/GetExtRobotList`,data);
}



//强制离线
export function SetOffline(data) {
  return $axios.post(`${baseURL}/Robot/SetOffline`,data);

}

//同步群消息
export function GetAllGroup(data) {
  return $axios.post(`${baseURL}/Robot/GetAllGroup`,data);

}

//重新登陆
export function InHouseLogin(data) {
  return $axios.post(`${baseURL}/Robot/InHouseLogin`,data);

}

//自动入群
export function UpdateIsAutoJoinGroup(data) {
  return $axios.post(`${baseURL}/Robot/UpdateIsAutoJoinGroup`,data);

}

//自动添加好友
export function UpdateIsAutoAddFriend(data) {
  return $axios.post(`${baseURL}/Robot/UpdateIsAutoAddFriend`,data);

}

//托管机器人二维码
export function InHouseQrcode(data) {
  data.skipToast=true
  return $axios.post(`${baseURL}/Robot/InHouseQrcode`,data);
}

//平台机器人邮箱列表
export function GetApplyEmail(data) {
  return $axios.post(`${baseURL}/Robot/GetApplyEmail`,data);
}

//平台机器人邮箱列表
export function AddApplyEmail(data) {
  return $axios.post(`${baseURL}/Robot/ApplyEmail`,data);

}

//获取机器人对应群列表
export function GetGroupList(data) {
  return $axios.post(`${baseURL}/Robot/GetGroupList`,data);

}

//获得机器人列表(简易版)
export function GetRobotSimpleList(data) {
  return $axios.post(`${baseURL}/Robot/SimpleList`,data);

}

//获得企业微信客户标签列表(简易版)
export function GetSimpleTagList(data) {
  return $axios.post(`${baseURL}/Robot/GetSimpleTagList`,data);

}

//获取机器人对应的群列表
export function GetPrivateSendRobotList(data) {
  return $axios.post(`${baseURL}/PrivateSend/GetRobotGroupList`,data);

}

//获取企业好友
export function RobotGetCompanyContact(data) {
  return $axios.post(`${baseURL}/Robot/GetCompanyContact`,data);
}

//获得废弃的机器人列表
export function RobotObsoleteList(data) {
  return $axios.post(`${baseURL}/Robot/ObsoleteList`,data);
}

//恢复机器人状态
export function RobotSetNormal(data) {
  return $axios.post(`${baseURL}/Robot/SetNormal`,data);
}

//设置废弃机器人
export function RobotSetObsolete(data) {
  return $axios.post(`${baseURL}/Robot/SetObsolete`,data);
}